import React from 'react';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import afterSchoolClubs from './../../assets/Section discover/afterSchoolClubs.jpg';
import birthdays from './../../assets/Section discover/birthdays.jpg';
import breakfastClubs from './../../assets/Section discover/breakfastClubs.jpg';
import camps from './../../assets/Section discover/camps.jpg';
import classes from './../../assets/Section discover/classes.jpg';
import clubs from './../../assets/Section discover/clubs.jpg';
import events from './../../assets/Section discover/events.jpg';
import inSchoolPrograms from './../../assets/Section discover/inSchoolPrograms.jpg';
import schoolTours from './../../assets/Section discover/schoolTours.jpg';
import workshops from './../../assets/Section discover/workshops.jpg';
import placestovisit from './../../assets/Section discover/PlacesToVisit.jpg';
import css from './SectionDiscover.module.css';
const SectionDiscover = props => {
  const { history, location, renderProviderLandingContent } = props;
  return (
    <div className={css.discoverSection}>
      <h2>
        {renderProviderLandingContent
          ? 'Join Ireland’s leading platform for children’s activities and grow your activity business.'
          : 'Discover and Explore'}
      </h2>
      {renderProviderLandingContent && <h3>Activities on our platform includes...</h3>}
      {false ? (
        <div className={css.listMyBusinessDiscoverContainer}>
          <div className={css.listMyBusinessDiscoverContent}>
            <h3>Save Time for What You Love</h3>
            <p>
              Marketing and finding new customers yourself is time consuming Activity Time makes it
              easy by doing the work for you. We love marketing and connecting you to activity
              consumers!
            </p>
          </div>
          <div className={css.listMyBusinessDiscoverContent}>
            <h3>No Setup Fees</h3>
            <p>
              There is no setup fee for claiming your profile and adding your first 3 listings with
              the Newbie Membership There is no long-term commitment as you can cancel the paid
              monthly and annual membership with just 30 day's advance notice.
            </p>
          </div>
          <div className={css.listMyBusinessDiscoverContent}>
            <h3>You Are Always in Control</h3>
            <p>
              When you take advantage of Activity Time's platform you set the activities, the
              prices, set the rules and remain in control of your business. You can accept bookings
              on your own site too. Activity Time takes care of finding the night people in the
              right places and brings them to you.
            </p>
          </div>
          <div className={css.listMyBusinessDiscoverContent}>
            <h3>Be in Good Company</h3>
            <p>
              Activity Time is partnering with Rids activity providers who love what they do and put
              energy into growing a worthwhile business. We are building a trustworthy space for
              parents and teacher to find what they need Customers will be encouraged to reten
              review providers activities so your will always be in good company.
            </p>
          </div>
        </div>
      ) : (
        <div className={css.discoverImages}>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'classes',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={classes} className={css.images} />
            <span>Classes</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'camps',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={camps} className={css.images} />
            <span>Camps</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'birthdayParty',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={birthdays} className={css.images} />
            <span>Birthdays</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'schoolTours',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={schoolTours} className={css.images} />
            <span>School Tours</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'workshops',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={workshops} className={css.images} />
            <span>Workshops</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'events',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={events} className={css.images} />
            <span>Events</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'club',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={clubs} className={css.images} />
            <span>Clubs</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'breakfastClub',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={breakfastClubs} className={css.images} />
            <span>Breakfast Club</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'afterSchoolClub',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={afterSchoolClubs} className={css.images} />
            <span>After School Clubs</span>
          </button>

          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'inSchoolProgrammes',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={inSchoolPrograms} className={css.images} />
            <span>In-School Programs</span>
          </button>
          <button
            className={css.imageButtons}
            onClick={() =>
              !renderProviderLandingContent &&
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  {
                    pub_categories: 'placesToVisit',
                    bounds: '85.051129,143.2360763,-85.051129,-143.2360763',
                  }
                )
              )
            }
          >
            <img src={placestovisit} className={css.images} />
            <span>Places to Visit</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default SectionDiscover;
